import React from 'react';
import './App.css';
import Image from 'react-bootstrap/Image';
import Cookies from 'universal-cookie';
import {withRouter} from "react-router-dom"
import axios from 'axios'
import product from './img/product.png'; // Tell webpack this JS file uses this image
import logo from './img/logo.png'
import Footer from "./Footer";
import tagline from "./img/tagline.png";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 200, (x - window.innerWidth / 2) / 200, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const cookies = new Cookies();

/*function ProductHolder() {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 500, friction: 100 } }))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <Image src={product} alt="Blood Orange Seltzer Can" className="img-fluid Product-Image no-gutters"/>
        </animated.div>
    )
}
*/

class Upload extends React.Component {

    constructor(props) {
        super(props);
        if (cookies.get('myAccess') !== 'True') {
            this.props.history.push("/");
        }
        this.btnTapped1 = this.btnTapped1.bind(this);
        this.btnTapped2 = this.btnTapped2.bind(this);
    }

    btnTapped1() {
        this.props.history.push("/submit-pic");
    }

    btnTapped2() {
        var that = this;
        axios.post('https://django.platinumseltzer.com/api/remind/', {
            email: cookies.get('myEmail'),
        }).then(function (response) {
            window.location = 'https://www.budlight.com/our-beers/next/'
        }).catch(function (response) {
            window.location = 'https://www.budlight.com/our-beers/next/'
        })
    }

    render() {
        return (
            <div className="App">
                <div className="App2">
                    <div className="container-fluid Main-Container  vh-100">
                        <div className="row h-100 w-100 justify-content-end">
                            <div className="Main-Center d-flex col-12 col-md-6">
                                <div className="container-fluid">
                                    <div className="row Top-Mobile align-items-end justify-content-center">
                                    </div>
                                    <div className="container-fluid transmiddle">
                                        <div className="contentright">
                                            <div className="fbnb43 ultrablue pt-1">Your Entry
                                                has <br/> been received!
                                            </div>
                                        </div>
                                        <div className="d-flex d-md-none col-12 justify-content-center">
                                            <Image src={product} alt="Corona Premier" className=" img-fluid Product-Image no-gutters"/>
                                        </div>
                                        <div className="d-flex col-12 justify-content-center">
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Main-Left d-none d-md-flex col-6">
                                <Image src={product} alt="Corona Premier" className="img-fluid Product-Image no-gutters"/>
                                <Image src={tagline} alt="Corona Premier" className="img-fluid Tag-Image no-gutters"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Upload);
