import React from 'react';
import Image from 'react-bootstrap/Image';
import Cookies from 'universal-cookie';
import {withRouter} from "react-router-dom"
import './App.css';
import NameForm from './NameForm';
import Footer from "./Footer";
import product from './img/product.png';
import logo from './img/logo.png'
import tagline from "./img/tagline.png";


const calc = (x, y) => [-(y - window.innerHeight / 2) / 200, (x - window.innerWidth / 2) / 200, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const cookies = new Cookies();

/*
function ProductHolder() {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 500, friction: 100 } }))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <Image src={product} alt="Ultra Seltzer Can" className="img-fluid Product-Image no-gutters"/>
        </animated.div>
    )
}
*/

class Registration extends React.Component {

    constructor(props) {
        super(props);
        if (cookies.get('myAccess') !== 'True') {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <div className="App">
                <div className="App2">
                    <div className="container-fluid Main-Container">
                        <div className="row h-100 w-100 justify-content-end">
                            <div className="Main-Center d-flex col-12 col-md-6">
                                <div className="container-fluid p-0 m-0">
                                    <div className="row Top-Mobile align-items-end justify-content-center">
                                        <div className="d-flex col-12 pt-5 justify-content-center">
                                            <Image src={logo} className="img-fluid Logo-Image" alt="Corona Premier"/>
                                        </div>
                                    </div>
                                    <div className="container-fluid transmiddle">
                                        <div className="contentright">
                                            <div className="fbnb200 ultrablue pt-2 pb-0">Join US <br /> For A Round</div>
                                            <div className="fbnm54 ultrablue pt-1">ENTER FOR A CHANCE TO WIN A TRIP TO THE US OPEN</div>
                                            <div className="fbnb24 ultrablue pt-1">Including tickets to Wed/Thurs Rounds, Hotel Stay, $750 Gift Card & More</div>
                                            <div className="fbnb100 ultrawhite pt-4 pb-2">Enter Your Info Below</div>
                                            <div><NameForm/></div>
                                        </div>
                                        <div className="contentmob2 d-flex d-md-none col-12 justify-content-center">
                                            <Image src={product} alt="Corona Premier" className=" img-fluid Product-Image no-gutters"/>
                                        </div>
                                        <div className="d-flex col-12 justify-content-center">
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Main-Left2 d-none d-md-flex col-6">
                                <Image src={product} alt="Corona Premier" className="img-fluid Product-Image no-gutters"/>
                                <Image src={tagline} alt="Corona Premier" className="img-fluid Tag-Image no-gutters"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Registration);
