import React from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {withRouter} from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import queryString from 'query-string'
import Cookies from 'universal-cookie';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const cookies = new Cookies();

// Our app
class FileForm extends React.Component {

    constructor(props) {
        super(props);
        const qvalues = queryString.parse(this.props.location.search)
        let tEmail = qvalues.email;
        if(cookies.get('myEmail')) {
            tEmail = cookies.get('myEmail');
        }
        this.state = {
            // Set initial files, type 'local' means this is a file
            // that has already been uploaded to the server (see docs)
            files: [],
            email:tEmail,
            show: false
        };
    }

    handleInit() {
        console.log("FilePond instance has initialised", this.pond);
    }

    render() {
        const handleClose = () => this.setState({ show: false })
        const handleOpen = () => this.setState({ show: true })
        const handleSucess = () => this.props.history.push("/thank-you2");
        return (
            <Formik
                initialValues={{
                    email: this.state.email,
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),
                })}
                onSubmit={values => {
                    if(this.state.files.length > 0){
                        const data = new FormData()
                        data.append("file", this.state.files[0]);
                        data.append('sweeps', 24)
                        data.append('email', values.email)
                        axios.post('https://django.platinumseltzer.com/api/photoentry/', data).then(function (response) {
                            handleSucess()
                        }).catch(function (response) {
                            handleOpen()
                        })
                    }else {
                        console.log("Empty")
                    }
                }}>
                {({errors, status, touched}) => (
                    <Form>
                        <Modal show={this.state.show} onHide={handleClose} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>An error has occured please try again later.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>We Apologize, The system was unable to save your entry please try again shortly.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="container-fluid File-Form-Container">
                        <div className="form-group">
                            <Field name="email" type="text" placeholder="Email"
                                   className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                                <FilePond
                                    ref={ref => (this.pond = ref)}
                                    files={this.state.files}
                                    allowMultiple={false}
                                    allowReorder={false}
                                    maxFiles={1}
                                    name="files"
                                    oninit={() => this.handleInit()}
                                    onupdatefiles={fileItems => {
                                        // Set currently active file objects to this.state
                                        this.setState({
                                            files: fileItems.map(fileItem => fileItem.file)
                                        });
                                    }}
                                />
                        </div>
                        <div className="form-group">
                            <button type="submit"  className="btn btn-primary Upload-Button mr-2">Upload</button>
                        </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}
export default withRouter(FileForm);
